import React from "react"
import * as Styles from "./image.module.scss"

function ImageGrid({ children, variation }) {
  const variationClass = variation ? variation : 'default'
  return (
    <div className={`${Styles.grid} ${variationClass}`}>
      {children}
    </div>
  )
}

export default ImageGrid