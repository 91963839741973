import React, { useState, useEffect } from "react"
import tw from 'twin.macro'
import Img from "gatsby-image"
import Div100vh from 'react-div-100vh'

import { pluralise } from "../../utils/helpers"
import ContributorPhoto from "./contributor"

import '@gouch/to-title-case'

const Banner = ({ title, summary, image, places, series, authors }) => {
  const [ didMount, setDidMount ] = useState(false)

  const styles = {
    introAnimation: () => [
      tw`opacity-0 transform[translateY(8%)] transition[0.6s ease-out transform, 0.6s ease opacity]`,
    ],
  }

  useEffect(() => {
    setDidMount(true)
  }, [])

  return (
    <Div100vh
      style={{ height: `100rvh` }}
      tw="relative overflow-hidden flex justify-end md:(justify-center) flex flex-col background[#000]">
      <div tw="z-30 py-8 px-5 max-w-3xl mx-auto md:(text-center)">
        { (authors) && 
            <div
              css={[
                styles.introAnimation(),
                tw`flex items-center justify-start md:(justify-center) transition-delay[0.25s]`,
                (didMount) && tw`opacity-100 transform[translateY(0%)]`
              ]}>
              {authors.map(({ name, path }) => {
                return (
                  <ContributorPhoto name={name} path={path}/>
              )})}
            </div>
          }
        <div
          css={[
            styles.introAnimation(),
            tw`text-xs md:(text-sm) text-white uppercase tracking-widest transition-delay[0.5s]`,
            (didMount) && tw`opacity-100 transform[translateY(0%)]`
          ]}>
          { (series) ? series : 'Collection' }
          <svg
            width="24"
            height="2"
            xmlns="http://www.w3.org/2000/svg"
            tw="inline-block mx-2 vertical-align[super]">
            <path
              d="M0 0h30v2H0z"
              fill="#fff"
              fillRule="evenodd"
              opacity=".534"
            />
          </svg>
            {places.length} {pluralise(places.length, 'place')}
          </div>
          <h1
            css={[
              styles.introAnimation(),
              tw`text-white leading-normal my-3 transition-delay[0.8s] font-serif text-3xl md:(text-5xl my-10) lg:(text-7xl)`,
              (didMount) && tw`opacity-100 transform[translateY(0%)]`
            ]}>
              {title.toTitleCase()}
          </h1>
          <p css={[
            styles.introAnimation(),
            tw`text-white text-lg md:(max-w-md mx-auto text-2xl) leading-normal transition-delay[1.2s] font-serif `,
            (didMount) && tw`opacity-100 transform[translateY(0%)]`
          ]}>{summary}</p>
        </div>
      <div tw="absolute inset-0 background[#3C3E41] z-10 opacity-50" />
      <Img
        css={[
          tw`absolute! inset-0 z-0 transform[scale(1.3)] opacity-0 transition-delay[1s] transition[opacity 1s ease, transform 12s ease-out]`,
          (didMount) && tw`opacity-100 transform[translateY(0%)]`
        ]}
        fluid={image}
        alt={title} />
    </Div100vh>
  )
}

export default Banner
