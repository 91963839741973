import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PlaceCard from "./card"

const PlaceLink = (props) => (
  <StaticQuery
    query={graphql`
      {
        allMdx(filter: {fields: {sourceName: {eq: "places"}}}) {
          edges {
            node {
              id
              fields {
                slug
                sourceDirectory
              }
              frontmatter {
                summary
                name
                price
                article {
                  title
                  url
                }
                locations {
                  suburb
                  title
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const matchedPlace = data.allMdx.edges.find(n => {
        return n.node.fields.sourceDirectory.includes(props.filename);
      });

      if (!matchedPlace) { return null; }
      const place = matchedPlace.node
      return (
        <PlaceCard key={place.id} place={place}/>
      );
    }}
  />
)

export default PlaceLink
