import React from "react"
import 'twin.macro'
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Layout from "../components/layout"
import Footer from "../components/footer"
import Seo from "../components/seo"
import { H2 } from "../components/typography"
import Banner from "../components/collection/banner"
import * as Article from '../components/article.module.scss'
import Place from "../components/place/link"
import PlaceGrid from "../components/place/grid"
import CollectionImage from "../components/collection/image"
import Summary from "../components/summary"
import ImageGrid from "../components/place/image-grid"

require('@gouch/to-title-case')

const CollectionTemplate = ({ data }) => {
  const collection = data.mdx
  const metadata = collection.frontmatter
  const shortcodes = { Place, CollectionImage, ImageGrid }

  return (
    <Layout styleContext="feature">
      <Seo
        title={metadata.title}
        image={`${collection.fields.slug.substring(0, collection.fields.slug.length - 1)}.jpg`}
        pathname={collection.fields.slug}
        description={`${metadata.summary.substring(0, 150)}`}
      />
      <Banner
        title={metadata.title}
        summary={metadata.summary}
        places={metadata.places}
        series={metadata.series}
        authors={metadata.authors}
        image={data.file.childImageSharp.fluid}
      />
      <div className={`${Article.collection} ${Article.typography}`} tw="font-serif text-thirdgray-900 dark:(text-thirdgray-dark-900)">
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{collection.body}</MDXRenderer>
        </MDXProvider>
      </div>
      <div tw="pt-4 pb-1">
        <H2 tw="text-center">Places in collection:</H2>
        <PlaceGrid>
          {metadata.places.map((place) =>
            <Place
              filename={place}
              key={place}/>
          )}
        </PlaceGrid>
      </div>
      <Summary/>
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $filename: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
        sourceDirectory
      }
      frontmatter {
        title
        series
        summary
        places
        date(formatString: "MMM DD, YYYY")
        updates {
          date(formatString: "MMM DD, YYYY")
          changes {
            type
            title
          }
        }
        authors {
          name
          path
        }
      }
      body
      timeToRead
    }
    file(relativeDirectory: {eq: $filename}, name: {eq: "hero"}) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default CollectionTemplate
